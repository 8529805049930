import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AbautsSkills from './AbautsSkills'; 
import SMain from './SMain';
import Abauts from './Abauts'
import Safety from './Safety'
import Information from './Information'
import Info from './Info';
export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<SMain />} />
        <Route path='/aboutskills' element={<AbautsSkills />} />
        <Route path='/abauts' element={<Abauts />} />
        <Route path='/safety' element={<Safety />} />
        <Route path='/information' element={<Information />} />
        <Route path='/info' element={<Info />} />
      </Routes>
    </BrowserRouter>
  );
}