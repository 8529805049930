import React from 'react'
import cl from './css/Info.module.css'
import Abb from './images/PDF/abb.pdf'
export default function Info() {
  return (
    <div className={cl.Info}>
        <div className={cl.Info__container}>
          <h1><a href={Abb} target="_blank">Абривиатура</a></h1>
        </div>
    </div>
  )
}
