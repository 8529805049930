import axios from 'axios';

const TELEGRAM_BOT_TOKEN = '6658001601:AAFtqJ93rTC4cXiwmQnGzIpEbLQvkgNAL24';
const TELEGRAM_CHAT_ID = '-1002021632223';

export const sendTelegramMessage = async (message) => {
  const telegramApiUrl = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;

  try {
    const response = await axios.post(telegramApiUrl, {
      chat_id: TELEGRAM_CHAT_ID,
      text: message
    });

    return response.data;
  } catch (error) {
    console.error('Ошибка при отправке сообщения в Telegram:', error);
    return null;
  }
};