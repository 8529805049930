import React from 'react'
import Header from './components/Header'
import "./css/App.css"
import About from './components/About'
import MainPruf from './components/MainPruf'
import Footer from './components/Footer'
export default function SMain() {
  return (
    <div>
      <Header/>
      <About/>
      <MainPruf/>
      <Footer/>
     
    </div>
  )
}
