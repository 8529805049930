import React from 'react'
import cl from './css/Abauts.module.css'
import ImgProf from './images/profile.jpg'
import Footer from "./components/Footer"
export default function Abauts() {
  return (
    <div className={cl.Abauts}>
        <h1>ОБО МНЕ</h1>
          <div className={cl.Abauts__container}>
            <img src={ImgProf} alt="" />
            <div>
              <span><h3>2008-2009</h3><p>Реализация проекта по оформлению участков многодетным семьям</p></span>
              <span><h3>2012</h3><p>Встреча с Президентом РФ по вопросу изменения условий программы "Молодая семья" в отношении интернациональных семей</p></span>
              <span><h3>2017</h3><p>Общественная работа по оформлению земельных участков ветеранам боевых действий</p></span>
              <span><h3>2020-2024</h3><p>Обучение руководителей и членов СНТ по юридически-грамотному землепользованию, создание авторских онлайн-курсов, в т. ч. бесплатных</p></span>
            </div>
          </div>
          <Footer />
    </div>
  )
}
