import React from 'react'
import cl from "./css/Information.module.css"
import Footer from './components/Footer'
import PC from './images/PDF/Политика_конфиденциальности_сайта.pdf'
import OZD from './images/PDF/Оферта_о_заключении_договора_купли.pdf'
import PP from './images/PDF/Правила_пользования_информационным_ресурсом.pdf'
export default function Information() {
  return (
    <div className={cl.Information}>
      <div className={cl.Information__container}>
        <span><a href={PC} target="_blank">
          Политика конфиденциальности, обработки персональных данных, использования файлов cookie
        </a>
        </span>


        <span>
          <a href={OZD} target="_blank">
          Оферта о заключении договора купли-продажи товара (услуги)

          </a>
        </span>


        <span>
          <a href={PP} target="_blank">
          Правила пользования информационным ресурсом
          </a>
        </span>
      </div>
      <Footer />
    </div>
  )
}
