import React from 'react'
import cl from './css/AbautsSkills.module.css'
import Logo from './components/UI/Logo'

export default function AbautsSkills() {

    return (
        <div className={cl.AbautsSkills}>
            <div className={cl.AbautsSkills__container}>
                <h1>Услуги</h1>
                <Logo />
                <p>Межевание земельного участка, оформление межевых и технических планов</p>
                <p>Оформление земельных участков с «нуля» от государства</p>
                <p>Оформление земельных участков участникам СВО (ветеранам боевых действий)</p>
                <p>Приватизация земельных участков и иной недвижимости</p>
                <p>Разрешение споров о границах земельных участков</p>
                <p>Защита от произвола органов власти</p>
                <p>Исключение межевых споров с соседями</p>
                <p>Изменение категории и видов разрешенного использования</p>
                <p>Обучение заработку на земельных участках</p>
                <p>Определение порядка пользования земельным участком и/или установление сервитута</p>
                <p>Правовой анализ документации при купле/продаже, аренде земельных участков</p>
                <p>Разрешение споров в суде, представительство в разных регионах</p>
                <p>Составление документов при сопровождении сделок</p>
                <p>Разрешение споров с застройщиками</p>
                <p>Разрешение споров с ППК «Роскадастр» (Росреестр)</p>
                <p>Уменьшение кадастровой стоимости</p>
                <p>Юридические консультации</p>

            </div>
        </div>
    )
}
