import React from 'react'
import cl from "./css/Safety.module.css"
import Footer from './components/Footer'
export default function Safety() {
    return (
        <div className={cl.Safety}>
            <div className={cl.Safety__container}>
                <p>
                    Обратите внимание, чтобы не попасть "в лапы" мошенников!</p>
                <p>
                    <span><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="red" class="bi bi-exclamation-lg" viewBox="0 0 16 16">
                        <path d="M7.005 3.1a1 1 0 1 1 1.99 0l-.388 6.35a.61.61 0 0 1-1.214 0zM7 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0" />
                    </svg></span> Внимательно проверяйте правильность написания адреса сайта, Телеграмм -канала, если переходите по ссылкам.</p>
                <p>
                    <span><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="red" class="bi bi-exclamation-lg" viewBox="0 0 16 16">
                        <path d="M7.005 3.1a1 1 0 1 1 1.99 0l-.388 6.35a.61.61 0 0 1-1.214 0zM7 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0" />
                    </svg></span> Я никогда не прошу куда-либо инвестировать</p>
                <p>
                    <span><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="red" class="bi bi-exclamation-lg" viewBox="0 0 16 16">
                        <path d="M7.005 3.1a1 1 0 1 1 1.99 0l-.388 6.35a.61.61 0 0 1-1.214 0zM7 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0" />
                    </svg></span> Я никогда не прошу вступить в платные каналы/группы</p>
                <p>
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="red" class="bi b36exclamation-lg" viewBox="0 0 16 16">
                        <path d="M7.005 3.1a1 1 0 1 1 1.99 0l-.388 6.35a.61.61 0 0 1-1.214 0zM7 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0" />
                    </svg>
                    
                    </span> <span>Вся информация о курсах, их стоимости только на официальном сайте <a href="https://aboutlandfromatob.ru">aboutlandfromatob.ru</a></span> </p>
        
            </div>
            <Footer></Footer>
        </div>
    )
}

