import React, { useEffect, useState } from 'react'
import cl from "../css/Header.module.css"
import Logo from './UI/Logo'
import Btn from './UI/Btn'
import Modal from './UI/Modal'




export default function Header() {
    const [modal, setModal] = useState(false)

    const [titleModal, setTitleModal] = useState('')

    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);

        // Очистка подписки
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <header className={cl.Header}>
            <div className={cl.Header__container}>
                <div className={cl.Header__left}>
                    <div className={cl.Header__logo__title}>

                        <h2>Земля от А до Я</h2>
                    </div>
                    <h3>Елена Сергеевна Шульгина</h3>
                    <p>Бесплатная первичная консультация.
                        ‌Оставьте заявку, и мы свяжемся с вами в течение 15 минут.</p>
                    <Btn title="Получить консультацию" customStyle={{ border: 'none' }} onClick={() => { setModal(true); setTitleModal("Получить консультацию") }} />
                    <Btn title="Записаться на курс" customStyle={{ background: '#7c4161' }} onClick={() => { setModal(true); setTitleModal("Записаться на курс") }} />
                </div>

                <div className={cl.Header__rigth}>
                    {isLargeScreen ? (
                        <Logo cus={{ width: '300px' }} />
                    ) : (
                        <Logo />
                    )}
                </div>
                <Modal viseble={modal} setViseble={setModal} setTitle={setTitleModal} title={titleModal} >

                </Modal>
            </div>
        </header>
    )
}
