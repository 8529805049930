import React from 'react'
import cl from '../css/MainPruf.module.css'
import Btn from "./UI/Btn"
import { Link } from 'react-router-dom'

const elementBlock = [
    { tile: 'Переход', nameBlock: "Меры безопасности", textBlock: "защитите себя от мошенников", imgBlock: "https://mosopora.ru/upload/000/u1/b8/c6/5ac09bf7.jpg" },
    { tile: 'Переход', nameBlock: "Политика сайта", textBlock: "правовая информация", imgBlock: "https://klike.net/uploads/posts/2023-01/1674373284_3-22.jpg" },
]
export default function MainPruf() {
    return (
        <div className={cl.MainPruf}>
            <div className={cl.MainPruf__block}>
                {elementBlock.map((el, index) => (
                    <div key={index}>
                        <h3>{el.nameBlock}</h3>
                        <img src={el.imgBlock} alt={el.nameBlock} />
                        <p>{el.textBlock}</p>
                        {index === 0 ? (
                            <Link to="/safety">
                                <Btn title={el.tile} />
                            </Link>
                        ) : (

                            <Link to="/information">
                                <Btn title={el.tile} />
                            </Link>
                        )}


                    </div>
                ))}
            </div>
        </div>
    )
}
