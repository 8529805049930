import React from 'react'
import cl from "../css/About.module.css"
import Btn from './UI/Btn'
import Сourse from '../images/PDF/программакурса.pdf'
import Keys from '../images/PDF/практикаделам_кейсы.pdf'
import { Link } from 'react-router-dom'


const elementBlock = [
    { link: '', titleText: "Услуги", bg: "#b84d5f", img: "https://757575.ru/wp-content/uploads/2023/09/1-AA-DEVENIR-CONSEILLER-JURIDIQUE-FIDULINK-DEVENIR-CONSEILLER-JURIDIQUE-FIDULINK.jpg", text: 'Перечень вопросов, которые мы можем решить', title: 'Переход' },
    { link: Сourse, titleText: "Экспресс- курс", bg: "#ff5a30 ", img: "https://megacarib.ru/uploads/s/z/4/o/z4oshj5lmeyf/img/full_uploaded_by_url_bWBy94eS.jpg", text: 'Основные стратегии работы с землей', title: 'Переход' },
    { link: '', titleText: "Полезная информация", bg: "#f3b859", img: "https://avatars.mds.yandex.net/get-altay/10812438/2a0000018a8d694e123d54cfb9902c9f3ba7/XXL_height", text: 'FAQ', title: 'Переход' },
    { link: Keys, titleText: "Успешные кейсы", bg: "#193761 ", img: "https://static.tildacdn.com/tild3766-3964-4135-a566-346236326230/1645514656_50-kartin.jpg", text: 'Наиболее интересные сложные споры', title: 'Переход' },
]


export default function About() {
    return (
        <div className={cl.About}>
            <div className={cl.About__container}>
                <h1>ОБО МНЕ</h1>
                <Link to="/Abauts" className={cl.linkAbout}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                    </svg>
                </Link>
                <p>15+ на рынке юридических услуг</p>
                <p>  1 500+ успешных кейсов (в том числе ВС РФ)</p>
                <p> 15 000+ консультаций</p>

                <div className={cl.Anout__block}>
                    {elementBlock.map((el, index) => (
                        <div key={index} style={{ background: el.bg }}>
                            <img src={el.img} alt="" />
                            <h2>{el.titleText}</h2>
                            <span>{el.text}</span>
                            {index === 0 ? (
                                <Link to="/aboutskills">
                                    <Btn title={el.title} customStyle={{ width: '100%', background: 'none' }} />
                                </Link>
                            ) : index === 2 ? (
                                <Link to="/info"> 
                                    <Btn title={el.title} customStyle={{ width: '100%', background: 'none' }} />
                                </Link>
                            ) : (
                                <a href={el.link} target="_blank" rel="noopener noreferrer">
                                    <Btn title={el.title} customStyle={{ width: '100%', background: 'none' }} />
                                </a>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
