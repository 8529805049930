import React from 'react'
import cl from "../css/Footer.module.css"
import Logo from "./UI/Logo"
import Diploma from '../images/диплом,Шульгина_page-0001.jpg'
const iconsFooter = [
    {
        link: 'https://t.me/aboutlandfromAtoB', icon: <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" fill="#ff5a30" class="bi bi-telegram" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09" />
        </svg>
    },
    {
        link: 'https://wa.me/89069101181', icon: <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" fill="#ff5a30" class="bi bi-whatsapp" viewBox="0 0 16 16">
            <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
        </svg>
    },
    {
        link: 'https://vk.com/aboutlandofatob', icon:
            <svg fill="#ff5a30" width="30px" height="30px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <title>vk</title>
                <path d="M25.217 22.402h-2.179c-0.825 0-1.080-0.656-2.562-2.158-1.291-1.25-1.862-1.418-2.179-1.418-0.445 0-0.572 0.127-0.572 0.741v1.968c0 0.53-0.169 0.847-1.566 0.847-2.818-0.189-5.24-1.726-6.646-3.966l-0.021-0.035c-1.632-2.027-2.835-4.47-3.43-7.142l-0.022-0.117c0-0.317 0.127-0.614 0.741-0.614h2.179c0.55 0 0.762 0.254 0.975 0.846 1.078 3.112 2.878 5.842 3.619 5.842 0.275 0 0.402-0.127 0.402-0.825v-3.219c-0.085-1.482-0.868-1.608-0.868-2.137 0.009-0.283 0.241-0.509 0.525-0.509 0.009 0 0.017 0 0.026 0.001l-0.001-0h3.429c0.466 0 0.635 0.254 0.635 0.804v4.34c0 0.465 0.212 0.635 0.339 0.635 0.275 0 0.509-0.17 1.016-0.677 1.054-1.287 1.955-2.759 2.642-4.346l0.046-0.12c0.145-0.363 0.493-0.615 0.9-0.615 0.019 0 0.037 0.001 0.056 0.002l-0.003-0h2.179c0.656 0 0.805 0.337 0.656 0.804-0.874 1.925-1.856 3.579-2.994 5.111l0.052-0.074c-0.232 0.381-0.317 0.55 0 0.975 0.232 0.317 0.995 0.973 1.503 1.566 0.735 0.727 1.351 1.573 1.816 2.507l0.025 0.055c0.212 0.612-0.106 0.93-0.72 0.93zM20.604 1.004h-9.207c-8.403 0-10.392 1.989-10.392 10.392v9.207c0 8.403 1.989 10.392 10.392 10.392h9.207c8.403 0 10.392-1.989 10.392-10.392v-9.207c0-8.403-2.011-10.392-10.392-10.392z"></path>
            </svg>
    },
    {
        link: { Diploma }, icon:
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="30px" height="30px" viewBox="0 0 2500.000000 2500.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,2500.000000) scale(0.100000,-0.100000)"
                    fill="#ff5a30 " stroke="none">
                    <path d="M10639 23931 c-884 -350 -1605 -638 -1603 -640 1 -2 384 -99 851
        -215 l848 -212 3 -397 2 -397 1515 0 1515 0 0 390 0 390 23 5 c100 24 558 111
        562 107 3 -3 5 -496 5 -1096 l0 -1091 -108 -330 -108 -330 414 -3 c228 -1 416
        -1 418 0 1 2 -29 118 -67 258 l-69 255 0 1213 0 1212 23 9 c12 4 222 54 467
        111 245 57 448 106 452 109 7 6 -3519 1293 -3532 1290 -3 0 -728 -287 -1611
        -638z"/>
                    <path d="M6518 23230 c-281 -51 -462 -210 -534 -470 -16 -58 -18 -122 -22
        -622 l-3 -558 -99 0 -100 0 0 -3507 c0 -3338 1 -3505 18 -3491 29 26 3336
        2594 3351 2602 14 9 13 -2692 -5 -10685 l-6 -2787 148 -6 c82 -4 184 -9 226
        -12 l77 -6 3 -1127 4 -1126 26 -90 c110 -373 394 -659 773 -778 121 -38 230
        -51 374 -44 243 11 464 97 665 256 177 141 311 348 373 576 l28 100 3 1078 3
        1077 239 0 239 0 4 -1078 c4 -1183 0 -1111 66 -1292 57 -154 140 -278 274
        -407 45 -43 111 -99 146 -122 94 -64 256 -138 368 -168 88 -24 115 -27 273
        -27 158 0 185 3 275 27 55 15 150 51 211 81 281 138 487 365 581 641 53 157
        53 152 53 1327 l0 1090 118 -6 c64 -4 138 -9 163 -12 l46 -5 6 2303 c4 1267 7
        4338 7 6825 0 2487 3 4524 8 4526 4 3 83 8 175 12 l168 7 -1 -3714 c-2 -3689
        -1 -3713 19 -3789 114 -437 578 -691 1001 -550 124 42 221 103 306 194 84 89
        127 160 165 269 38 114 49 207 49 432 0 106 3 234 6 284 l7 92 520 0 519 0 -6
        43 c-63 436 -950 7323 -956 7422 -26 416 -172 759 -440 1039 -328 342 -742
        539 -1310 622 -96 14 -227 18 -810 21 l-695 5 -505 -1098 -505 -1098 -3 1112
        -2 1112 -295 0 -295 0 0 -1107 0 -1108 -503 1087 -502 1088 -1647 0 -1648 0 0
        945 0 945 -145 0 -145 0 0 490 c0 270 -4 490 -9 490 -4 0 -11 30 -14 68 -28
        296 -194 504 -467 582 -83 24 -326 36 -412 20z"/>
                    <path d="M10753 21753 c-5 -16 -7 -84 -7 -153 6 -522 295 -1014 764 -1298 460
        -279 1074 -282 1539 -7 301 178 540 456 666 774 78 197 126 473 110 637 l-7
        74 -1529 0 -1529 0 -7 -27z"/>
                </g>
            </svg>


    },
]


function toHref(link) {
    window.location.href = link
}

export default function Footer() {
    return (
        <footer className={cl.Footer}>
            <div className={cl.Footer__container}>
                <div>
                    <Logo cus={{ width: '150px' }} />
                </div>
                <div>
                    {iconsFooter.map((el, index) => (
                        <ul key={index}>
                            <li>
                                {index === 3 ?
                                    <a href={Diploma} target="_blank" rel="noopener noreferrer">{el.icon}</a> :
                                    <div onClick={() => toHref(el.link)}>{el.icon}</div>
                                }
                            </li>
                        </ul>
                    ))}
                </div>
                <div>
                    <ul>
                        <li>ИП Шульгина Елена Сергеевна</li>
                        <li>ИНН 246306442054</li>
                        <li>ОГРНИП 318246800096832</li>
                        <li>Реквизиты счета получателя</li>
                        <li>Номер счета: 40817810800061554288</li>
                        <li>Банк-получатель: АО «Тинькофф Банк»</li>
                        <li>Корр. счет: 30101810145250000974</li>
                        <li>БИК: 044525974</li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}
