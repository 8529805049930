import React from 'react'
import img from "../../images/logo.svg"
import "../../css/Logo.css"
export default function Logo({cus}) {
  return (
       <div>
         <img src={img} alt="logo" style={cus}/>
       </div>
  )
}
