import React, { useState } from 'react'
import cl from '../../css/Modal.module.css'
import Btn from "./Btn"
import PC from '../../images/PDF/Политика_конфиденциальности_сайта.pdf';
import { sendTelegramMessage } from '../../telegramBot'

export default function Modal({ viseble, setViseble, title }) {
    const [isAgreedPersonalData, setIsAgreedPersonalData] = useState(false);
    const [isAgreedNotifications, setIsAgreedNotifications] = useState(false);

    function handlePersonalDataCheckboxChange(event) {
        setIsAgreedPersonalData(event.target.checked);
    }

    function handleNotificationsCheckboxChange(event) {
        setIsAgreedNotifications(event.target.checked);
    }

    const rootClass = [cl.Modal]
    if (viseble) {
        rootClass.push(cl.active)
    }

    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
    });

    function handleChange(event) {
        const { name, value } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    }

    async function handleSubmit(event) {
        event.preventDefault();
        if (!isAgreedPersonalData || !isAgreedNotifications) {
            alert("Необходимо согласиться с условиями");
            return;
        }

        const message = `
            Новая заявка:\n
            ${title}\n
            Имя: ${formData.name}\n
            Телефон: ${formData.phone}\n
            Email: ${formData.email}
        `;
        
        const success = await sendTelegramMessage(message);
        console.log(success);
        setViseble(false)
        
    }


    return (
        <div className={rootClass.join(' ')} onClick={() => setViseble(false)}>
            <div className={cl.ModalContent} onClick={e => e.stopPropagation()}>
                <div className={cl.ModalHader} >

                    <svg onClick={() => setViseble(false)} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                    </svg>
                </div>
                <div className={cl.ModalBody} >
                    <form>
                        <h2>{title}</h2>
                        
                        <input type="text" placeholder='Имя' name="name" value={formData.name} onChange={handleChange} />

                        <input type="tel" placeholder='Телефон' name="phone" value={formData.phone} onChange={handleChange} />

                        <input type="email" placeholder='Email' name="email" value={formData.email} onChange={handleChange} />
                    </form>
                </div>
                <div className={cl.ModalFooter}>
                    <form>
                        <div>
                            <label>
                            <input type="checkbox" className={cl.realCheckBox} onChange={handlePersonalDataCheckboxChange} checked={isAgreedPersonalData} />
                                <span className={cl.customCheckBox}></span>
                            </label>

                            <span>Я согласен на обработку моих персональных данных в соответствии с <a href={PC} target="_blank">Политикой конфиденциальности</a></span>
                        </div>
                        <div>
                            <label>
                            <input type="checkbox" className={cl.realCheckBox} onChange={handleNotificationsCheckboxChange} checked={isAgreedNotifications} />
                                <span className={cl.customCheckBox}></span>
                            </label>

                            <span>Я <a href={PC} target="_blank">согласен</a> получать уведомления</span>
                        </div>
                    </form>
                 
                    <Btn title="ОТПРАВИТЬ" onClick={handleSubmit}>

                    </Btn>
                </div>

            </div>

        </div>
    )
}
